import React from 'react';
import { graphql } from 'gatsby';

import SEO from 'src/components/Seo/Seo';
import Products from 'src/components/Products/Products';
import Main from 'src/components/Main';
import Banner from 'src/components/Banner';

interface TripsPageProps {
  data: any;
}

const TripsPage: React.FC<TripsPageProps> = ({ data }) => {
  const {
    wpPage: { tripsFields: acfFields, seo },
  } = data;

  return (
    <>
      <SEO
        title={seo?.title}
        description={seo?.metaDesc}
        image={seo?.opengraphImage?.localFile}
      />

      <Banner
        image={acfFields.headerImage.localFile.childImageSharp.gatsbyImageData}
        title={acfFields.title}
        subtitle={acfFields.subtitle}
      />

      <Main>
        <Products columns={4} />
      </Main>
    </>
  );
};

export const query = graphql`
  query Trips {
    wpPage(slug: { eq: "keliones" }) {
      ...YoastSeoPage
      tripsFields {
        title
        subtitle
        headerImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

export default TripsPage;
